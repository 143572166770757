import React from 'react'
import { Helmet } from 'react-helmet'

const Confidentialite = () => {
  return (
    <>
      <Helmet>
        <title>Politique de confidentialité | Paramedic</title>
      </Helmet>
      <div style={{ margin: '8rem auto' }} className='container content'>
        <h1 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}>
          <span>Politique de
            confidentialité
          </span>
        </h1>
        <p>
          <span>Le Règlement Général sur la Protection des
            Données (RGPD) entre en vigueur le 25 mai 2018 et vient encadrer le cadre légal
            applicable à la protection des données personnelles. Il s’agit d’un enjeu fort et d’une
            priorité pour toutes les entreprises. Encore plus pour Paramedic.
          </span>
        </p>
        <p>
          <span>Paramedic a pour volonté d’établir avec ses
            utilisateurs et professionnels de santé des relations solides, fondées sur la confiance
            réciproque : garantir la sécurité et la confidentialité des données personnelles de ses
            utilisateurs est une priorité absolue pour Paramedic.
          </span>
        </p>
        <p>
          <span>Paramedic applique une politique très stricte
            pour garantir la protection des données personnelles de santé de ses utilisateurs
            :
          </span>
        </p>
        <p>
          <span>&#8211; Chaque utilisateur de Paramedic reste
            maître de ses données. Paramedic n’en dispose pas librement.
          </span>
        </p>
        <p>
          <span>&#8211; Elles sont traitées de manière
            transparente, confidentielle et sécurisée.
          </span>
        </p>
        <p>
          <span>&#8211; Paramedic est engagé dans une
            démarche continue de protection des données de ses utilisateurs.
          </span>
        </p>
        <p>
          <span>&#8211; Paramedic dispose d’une équipe dédiée
            à la protection des données personnelles, un chef de projet et DPO (Data Protection
            Officer déclaré auprès de la CNIL), d’un Directeur de la sécurité et d’un ingénieur
            spécialisé.
          </span>
        </p>
        <p>
          <span>&#8211; Les données personnelles de santé des
            utilisateurs sont hébergées par un prestataire ayant reçu l’agrément HDS (Hébergeur de
            Données de Santé) délivré par l’Asip Santé (Agence des Systèmes d’Information Partagés
            de Santé).
          </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}>
          <span>OBJET DE LA PRÉSENTE POLITIQUE</span>
        </h3>
        <p>
          <span>Paramedic souhaite vous informer par
            l’intermédiaire de la présente politique de la manière dont nous protégeons vos données
            à caractère personnel traitées via le dispatch Paramedic, le site
            https://www.paramedic.tech/ ou via les applications « Paramedic » , « Paramedic
            régulateur » disponible sur web et mobile. Ainsi que « Paramedic Ambulancier » sur
            mobile uniquement.
          </span>
        </p>
        <p>
          <span>La présente politique décrit la manière dont
            Paramedic et les professionnels de santé abonnés à ses services traitent les données à
            caractère personnel des visiteurs et des utilisateurs (ci-après le/les « Utilisateur(s)
            ») lors de leur navigation sur le site www.Paramedic.fr (ci-après le « Site ») et de
            leur utilisation des services de Paramedic ou de son application mobile (ci-après
            l’”Application”).
          </span>
        </p>
        <p>
          <span>Certaines Données Personnelles de
            l’Utilisateur doivent être considérées comme des données à caractère personnelles de
            santé, et sont ci-après dénommées « Données Personnelles de Santé ».
          </span>
        </p>
        <p>
          <span>Cette politique fait partie
            intégrante des
            Conditions Générales
            d’Utilisation
            du Site.
          </span>
        </p>
        <p>
          <span>IDENTITE ET COORDONNEES DES RESPONSABLES DE
            TRAITEMENT
          </span>
        </p>
        <p>
          <span>
            <i>
              <span>Rappel légal : Le
                responsable du traitement est, au sens de la LIL, la personne qui détermine les
                moyens et les finalités du traitement. Le sous-traitant est une personne
                traitant des données à caractère personnel pour le compte du responsable du
                traitement. Il agit sous l’autorité du responsable du traitement et sur
                instruction de celui-ci.
              </span>
            </i>
          </span>
        </p>
        <p>
          <span>Le responsable du traitement des Données
            Personnelles est :
          </span>
        </p>
        <ol>
          <li><span>Pour les
            Données Personnelles de Santé collectées (i) par l’Utilisateur lors de la demande de
            transport sanitaire directement via le Site ou l’Application ou (ii) par le
            Professionnel de Santé : chaque Professionnel de Santé individuellement qui utilise
            le service. Chaque professionnel de santé est considéré comme responsable de
            traitement. Paramedic est sous-traitant : il agit sur instruction particulière de
            chaque Professionnel de Santé.
              </span>
          </li>
          <li><span><span>Pour les Données Personnelles collectées dans le cadre
            de la création du compte du professionnel de santé et de sa navigation sur le
            Site ou son utilisation de l’Application: Paramedic,
                    </span><i><span>Société par
            Actions Simplifiée
            au capital de
            100.000€, immatriculée au RCS de Paris sous le numéro 830 635 272 et
            dont le
            siège social est situé au 15 allées de l’Europe (N° TVA
            intracommunautaire :
            FR 78830635272)</span>
                 </i><span> (ci-avant et
                             ci-après « Paramedic »). Paramedic est représentée par André Delaforge, son
                             Président.</span>
          </span>
          </li>
        </ol>
        <p><span>Qu’elle soit responsable de traitement ou
          sous-traitant, Paramedic prend les mesures propres à assurer la protection et la
          confidentialité des informations nominatives qu’elle détient ou qu’elle traite. Pour plus d’information concernant les
          services proposés par Paramedic vous pouvez vous référer aux Conditions générales
          d’utilisation du Site.
           </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>COLLECTE &amp; ORIGINE DES DONNÉES</span>
        </h3>
        <p><span>Toutes les données concernant les
          Utilisateurs sont collectées directement auprès de ces derniers.
           </span>
        </p>
        <p><span>Paramedic s’engage à recueillir le
          consentement de ses Utilisateurs et/ou à leur permettre de s’opposer à l’utilisation de
          leurs données pour certaines finalités, dès que cela est nécessaire.
           </span>
        </p>
        <p><span>Dans tous les cas, les Utilisateurs sont
          informés des finalités pour lesquelles leurs données sont collectées via les différents
          formulaires de collecte de données en ligne.
           </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>FINALITE DES DONNEES COLLECTEES</span></h3>
        <p><span>1. Nécessité de la collecte.</span></p>
        <p><span>Lors de la demande de transport sanitaire en
          ligne, l’équipe de dispatch Paramedic ou le professionnel de santé, communique certaines
          Données Personnelles. Le Professionnel de Santé et Utilisateur s’engage à obtenir le
          consentement du patient. Si le patient ne souhaite pas communiquer les informations qui
          lui sont demandées, il se peut que le Professionnel de Santé ne puisse pas accéder à
          certaines parties du Site ou de l’Application, et que Paramedic soit dans
          l’impossibilité de répondre à sa demande.
           </span>
        </p>
        <p><span>2. Finalités</span></p>
        <p><span>Le recueil de vos Données Personnelles a pour
          base légale : &#8211; l’intérêt légitime de Paramedic à assurer la meilleure qualité de
          ses services, à fournir à ses Utilisateurs le meilleur suivi possible de leurs
          rendez-vous et de leurs parcours de soins, et à améliorer le fonctionnement de son Site
          et de son Application &#8211; l’intérêt légitime de Paramedic à réaliser des enquêtes de
          satisfaction facultatives sur ses services en vue de les améliorer &#8211; le
          consentement de ses Utilisateurs lorsque celui-ci est requis par la réglementation en
          vigueur, notamment en matière de prospection commerciale et de cookies.
           </span>
        </p>
        <p><span>Les données des Utilisateurs de Paramedic
          sont principalement traitées pour : &#8211; permettre leur navigation sur le Site et
          permettre leur utilisation de l’Application &#8211; les mettre en relation avec un
          transporteur sanitaire &#8211; leur permettre de gérer leurs demandes de transports
          sanitaires (obtenir des notifications, les modifier, les annuler) et &#8211; leur
          permettre d’être pris en charge de façon optimale par le Transporteur sanitaire.
           </span>
        </p>
        <p><span>A titre subsidiaire les données des
          Utilisateurs sont également collectées pour : &#8211; prévenir et lutter contre la
          fraude informatique (spamming, hacking…) &#8211; améliorer la navigation sur le Site et
          l’utilisation de l’Application &#8211; mener des enquêtes de satisfaction facultatives
          sur les services de Paramedic (ces enquêtes sont réalisées de manière anonyme ou sont
          anonymisées à bref délai) &#8211; effectuer des statistiques sur l’utilisation de
          l’outil, un reporting interne pour les équipes de recherche &amp; développement de
          Paramedic ainsi qu’un reporting à destination des Professionnels de Santé (sans
          qu’aucune Donnée de Santé ne soit utilisée).
           </span>
        </p>
        <p><span>Le caractère obligatoire ou facultatif des
          données personnelles demandées et les éventuelles conséquences d&rsquo;un défaut de
          réponse à l’égard des Utilisateurs de Paramedic sont précisés lors de leur(s)
          collecte(s).
           </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>TYPES DE DONNÉES TRAITÉES</span></h3>
        <p><span>Paramedic est susceptible de traiter, en tant
          que Sous-traitant, pour permettre la demande de transport sanitaire, la gestion des
          transports sanitaires et leur suivi en ligne, tout ou partie des données suivantes :
          &#8211; Nom, prénom, date de naissance &#8211; Numéro de téléphone, adresse email,
          adresse postale (éventuellement digicode) &#8211; Mot de passe &#8211; Note
          complémentaire concernant le transport, service de l’hôpital concerné, éventuellement
          fréquence des rendez-vous &#8211; Historique des transports &#8211; Données appartenant
          au dossier médical que le professionnel de santé souhaite partager avec
          Paramedic.
           </span>
        </p>
        <p><span>Paramedic est susceptible de traiter, en tant
          que Responsable de Traitement, pour permettre la demande de transport sanitaire, la
          gestion des transports, le suivi en ligne du transport et l’historique des transports,
          tout ou partie des données suivantes : &#8211; pour permettre la navigation sur le Site
          ou l’utilisation de l’Application : données de connexion et d’utilisation du Site ou de
          l’Application &#8211; pour prévenir et lutter contre la fraude informatique (spamming,
          hacking…) : matériel informatique utilisé pour la navigation, l’adresse IP, le mot de
          passe (hashé) &#8211; pour améliorer la navigation sur le Site ou l’utilisation de
          l’Application : données de connexion et d’utilisation &#8211; pour mener des enquêtes de
          satisfaction facultatives sur Paramedic : adresse email &#8211; pour mener des campagnes
          de communication (sms, mail) : numéro de téléphone, adresse email
           </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>NON-COMMUNICATION DES DONNÉES
          PERSONNELLES
        </span>
        </h3>
        <p><span>Les Données Personnelles de l’Utilisateur ne
          seront pas transmises à des acteurs commerciaux ou publicitaires.
           </span>
        </p>
        <p><span>Les Données Personnelles de l’Utilisateur
          peuvent être traitées par des filiales de Paramedic et des sous-traitants (prestataires
          de services), dans le respect absolu du principe énoncé ci-dessus, exclusivement afin de
          réaliser les finalités de la présente politique.
           </span>
        </p>
        <p><span>Dans la limite de leurs attributions
          respectives et pour les finalités rappelées ci-dessus, les principales personnes
          susceptibles d’avoir accès aux données des Utilisateurs (hors Données de Santé) de
          Paramedic sont principalement les agents de notre service client.
           </span>
        </p>
        <p><span>De plus, afin de respecter les dispositions
          du Code de la santé publique concernant les Données Personnelles de Santé, Paramedic a
          recours à des Hébergeurs Données de Santé (dits “HDS”) bénéficiant de l’agrément délivré
          par le Ministère de la Santé.
           </span>
        </p>
        <p><span>Paramedic a recourt également aux prestations
          fournies par plusieurs sociétés spécialisées (mailing, analyse d’audience) dont la liste
          peut être communiquée aux personnes concernées sur demande adressée à
          protection-donnees@paramedic.tech. Aucune Donnée Personnelle de Santé ne leur est
          communiquée. Si ces sociétés utilisent des serveurs en dehors de l’Union Européenne,
          nous avons conclu avec elles des contrats spécifiques et des clauses contractuelles
          types établies par la Commission Européenne pour encadrer et sécuriser le transfert de
          vos données à ces prestataires.
           </span>
        </p>
        <h3 />
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>DURÉE DE CONSERVATION DES DONNÉES</span>
        </h3>
        <p><span>Nous conservons vos données uniquement le
          temps nécessaire pour les finalités poursuivies.
           </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>LES DROITS DES UTILISATEURS</span></h3>
        <p><span>Chaque fois que Paramedic traite des Données
          Personnelles, Paramedic prend toutes les mesures raisonnables pour s’assurer de
          l’exactitude et de la pertinence des Données Personnelles au regard des finalités pour
          lesquelles Paramedic les traite.
           </span>
        </p>
        <p><span><span>Conformément à la
          réglementation européenne en vigueur, les Utilisateurs de Paramedic disposent des
          droits suivants : &#8211; droit d&rsquo;accès (article 15 RGPD) et de rectification
          (article 16 RGPD), de mise à jour, de complétude des données des Utilisateurs
          &#8211; droit de verrouillage ou d’effacement des données des Utilisateurs à
          caractère personnel (article 17 du RGPD), lorsqu’elles sont inexactes, incomplètes,
          équivoques, périmées, ou dont la collecte, l&rsquo;utilisation, la communication ou
          la conservation est interdite
                 </span><span>(en savoir
          plus)</span><span> &#8211; droit de retirer à tout moment
                     un consentement (article 13-2c RGPD) &#8211; droit à la limitation du traitement des
                     données des Utilisateurs (article 18 RGPD) &#8211; droit d’opposition au traitement
                     des données des Utilisateurs (article 21 RGPD) </span><span>
              &#8211; droit à la portabilité des données que les Utilisateurs auront fournies,
              lorsque ces données font l’objet de traitements automatisés fondés sur leur
              consentement ou sur un contrat (article 20 RGPD).
                        </span>
        </span>
        </p>
        <p><span>Dès que Paramedic a connaissance du décès
          d’un Utilisateur et à défaut d’instructions de sa part, Paramedic s’engage à détruire
          ses données, sauf si leur conservation s’avère nécessaire à des fins probatoires ou pour
          répondre à une obligation (telle que la conservation du dossier patient).
           </span>
        </p>
        <p><span>Si l’Utilisateur souhaite savoir comment
          Paramedic utilise ses Données Personnelles, demander à les rectifier ou s’oppose à leur
          traitement, l’Utilisateur peut contacter Paramedic par écrit à l’adresse suivante :
          Paramedic – DPO, 15 allées de l’Europe, 92110 Clichy ou par mail à
          protection-donnees@paramedic.tech. Dans ce cas, l’Utilisateur doit indiquer les Données
          Personnelles qu’il souhaiterait que Paramedic corrige, mette à jour ou supprime, en
          s’identifiant de manière précise avec une copie d’une pièce d’identité (carte d’identité
          ou passeport).
           </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>« COOKIES » ET BALISES (TAGS)
          INTERNET
        </span>
        </h3>
        <ol>
          <li><span>« COOKIES
            »
              </span>
          </li>
        </ol>
        <p><span>Un « cookie » est un petit fichier
          d’information envoyé sur le navigateur de l’Utilisateur et enregistré au sein du
          terminal de l’Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies »). Ce
          fichier comprend des informations telles que le nom de domaine de l’Utilisateur, le
          fournisseur d’accès Internet de l’Utilisateur, le système d’exploitation de
          l’Utilisateur, ainsi que la date et l’heure d’accès. Les Cookies ne risquent en aucun
          cas d’endommager le terminal de l’Utilisateur.
           </span>
        </p>
        <p><span>Paramedic est susceptible de traiter les
          informations de l’Utilisateur concernant sa visite du Site, telles que les pages
          consultées, les recherches effectuées, à l’exclusion des Données de Santé. Ces
          informations permettent à Paramedic d’améliorer le contenu du Site, de la navigation de
          l’Utilisateur.
           </span>
        </p>
        <p><span>Les Cookies facilitant la navigation et/ou la
          fourniture des services proposés par le Site, l’Utilisateur peut configurer son
          navigateur pour qu’il lui permette de décider s’il souhaite ou non les accepter de
          manière à ce que des Cookies soient enregistrés dans le terminal ou, au contraire,
          qu’ils soient rejetés, soit systématiquement, soit selon leur émetteur. L’Utilisateur
          peut également configurer son logiciel de navigation de manière à ce que l’acceptation
          ou le refus des Cookies lui soient proposés ponctuellement, avant qu’un Cookie soit
          susceptible d’être enregistré dans son terminal. Paramedic informe l’Utilisateur que,
          dans ce cas, il se peut que les fonctionnalités de son logiciel de navigation ne soient
          pas toutes disponibles.
           </span>
        </p>
        <p><span>Si l’Utilisateur refuse l’enregistrement de
          Cookies dans son terminal ou son navigateur, ou si l’Utilisateur supprime ceux qui y
          sont enregistrés, l’Utilisateur est informé que sa navigation et son expérience sur le
          Site peuvent être limitées. Cela pourrait également être le cas lorsque Paramedic ou
          l’un de ses prestataires ne peut pas reconnaître, à des fins de compatibilité technique,
          le type de navigateur utilisé par le terminal, les paramètres de langue et d’affichage
          ou le pays depuis lequel le terminal semble connecté à Internet.
           </span>
        </p>
        <p><span>Le cas échéant, Paramedic décline toute
          responsabilité pour les conséquences liées au fonctionnement dégradé du Site et des
          services éventuellement proposés par Paramedic, résultant (i) du refus de Cookies par
          l’Utilisateur (ii) de l’impossibilité pour Paramedic d’enregistrer ou de consulter les
          Cookies nécessaires à leur fonctionnement du fait du choix de l’Utilisateur. Pour la
          gestion des Cookies et des choix de l’Utilisateur, la configuration de chaque navigateur
          est différente. Elle est décrite dans le menu d’aide du navigateur, qui permettra de
          savoir de quelle manière l’Utilisateur peut modifier ses souhaits en matière de
          Cookies.
           </span>
        </p>
        <p><span>À tout moment, l’Utilisateur peut faire le
          choix d’exprimer et de modifier ses souhaits en matière de Cookies. Paramedic pourra en
          outre faire appel aux services de prestataires externes pour l’aider à recueillir et
          traiter les informations décrites dans cette section.
           </span>
        </p>
        <p><span><span>Ces types de cookies ne sont
          déposés sur vos terminaux qu’à condition que vous y consentiez, en continuant votre
          navigation sur le Site Internet ou l’application mobile de Paramedic. À tout moment,
          l’Utilisateur peut néanmoins revenir sur son consentement à ce que Paramedic dépose
          ce type de cookies via notre
                 </span><span>Charte de
          gestion des cookies</span><span>.</span>
        </span>
        </p>
        <p><span><span>Pour en savoir plus, sur la
          manière dont sont gérés ces différents cookies et pour autoriser ou vous opposer à
          leur dépôt, vous devez vous rendre sur notre
                 </span><span>Charte de gestion des
          cookies</span><span>.</span>
        </span>
        </p>
        <ol>
          <li><span>BALISES
            (“TAGS”) INTERNET
              </span>
          </li>
        </ol>
        <p><span>Paramedic peut employer occasionnellement des
          balises Internet (également appelées « tags », ou balises d’action, GIF à un pixel, GIF
          transparents, GIF invisibles et GIF un à un) et les déployer par l’intermédiaire d’un
          partenaire spécialiste d’analyses Web susceptible de se trouver (et donc de stocker les
          informations correspondantes, y compris l’adresse IP de l’Utilisateur) dans un pays
          étranger.
           </span>
        </p>
        <p><span>Ces balises sont placées à la fois dans les
          publicités en ligne permettant aux internautes d’accéder au Site, et sur les différentes
          pages de celui-ci. Cette technologie permet à Paramedic d’évaluer les réponses des
          visiteurs face au Site et l’efficacité de ses actions (par exemple, le nombre de fois où
          une page est ouverte et les informations consultées), ainsi que l’utilisation de ce Site
          par l’Utilisateur. Le prestataire externe pourra éventuellement recueillir des
          informations sur les visiteurs du Site et d’autres sites Internet grâce à ces balises,
          constituer des rapports sur l’activité du Site à l’attention de Paramedic, et fournir
          d’autres services relatifs à l’utilisation de celui-ci et d’Internet.
           </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>RÉSEAUX SOCIAUX</span></h3>
        <p><span>L’Utilisateur de Paramedic a la possibilité
          de cliquer sur les icônes dédiées aux réseaux sociaux Twitter, Facebook, Linkedin et
          Google Plus figurant sur le Site ou dans l’Application de Paramedic.
           </span>
        </p>
        <p><span>Les réseaux sociaux permettent
          d&rsquo;améliorer la convivialité du Site et aident à sa promotion via les partages. Les
          services de partage de vidéo permettent d&rsquo;enrichir le Site de Paramedic de
          contenus vidéo et augmentent sa visibilité.
           </span>
        </p>
        <p><span>Lorsque l’Utilisateur clique sur ces boutons,
          Paramedic pourra avoir accès aux informations personnelles que l’Utilisateur aura
          indiquées comme publiques et accessibles depuis ses profils Twitter, Facebook, Linkedin
          et Google plus. Cependant, Paramedic ne crée ni n’utilise aucune base de données
          indépendante de Twitter, Facebook, Linkedin et Google Plus à partir des informations
          personnelles que l’Utilisateur peut y publier et Paramedic ne traitera aucune donnée
          relevant de sa vie privée par ce biais.
           </span>
        </p>
        <p><span>Si l’Utilisateur ne souhaite pas que
          Paramedic ait accès aux informations personnelles publiées sur l’espace public de ses
          profils ou de ses comptes sociaux, l’Utilisateur devra alors utiliser les moyens mis à
          sa disposition par Twitter, Facebook, Linkedin et Google Plus afin de limiter l’accès à
          ses données.
           </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>SÉCURITÉ</span></h3>
        <p><span>Paramedic met en œuvre toutes les mesures
          techniques et organisationnelles afin d’assurer la sécurité des traitements de données à
          caractère personnel et la confidentialité de Données Personnelles.
           </span>
        </p>
        <p><span>A ce titre, Paramedic prend toutes les
          précautions utiles, au regard de la nature des données et des risques présentés par le
          traitement, afin de préserver la sécurité des données et, notamment, d’empêcher qu’elles
          soient déformées, endommagées, ou que des tiers non autorisés y aient accès (protection
          physique des locaux, procédés d’authentification avec accès personnel et sécurisé via
          des identifiants et mots de passe confidentiels, journalisation des connexions,
          chiffrement de certaines données…).
           </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>INFORMATIONS PERSONNELLES ET MINEURS</span>
        </h3>
        <p><span>En principe, le Site et l’Application
          s’adressent à des personnes majeures capables de contracter des obligations.
           </span>
        </p>
        <p><span>L&rsquo;Utilisateur mineur de moins de 16 ans
          ou incapable doit obtenir le consentement préalable de son responsable légal
          préalablement à la saisie de ses données sur le Site et l’Application. Sauf obligation
          légale de confidentialité ou liée au secret médical, Paramedic pourra directement
          informer ce responsable (i) des catégories spécifiques de Données Personnelles
          recueillies auprès de la personne mineure (ii) de la possibilité de s’opposer à la
          collecte, l’utilisation ou la conservation de celles-ci.
           </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>NOUS CONTACTER &#8211; COORDONNÉES DU
          DPO
        </span>
        </h3>
        <p><span>Si l’Utilisateur a des questions ou des
          réclamations concernant le respect par Paramedic de la présente Politique, ou si
          l’Utilisateur souhaite faire part à Paramedic de recommandations ou des commentaires
          visant à améliorer la qualité de la présente politique, l’Utilisateur peut contacter
          Paramedic par écrit à l’adresse suivante : Paramedic – DPO 15 allées de l’Europe ou
          protection-donnees@paramedic.tech
           </span>
        </p>
        <p>&nbsp;</p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>RESPONSABLES DE LA COLLECTE DES DONNEES
          PERSONNELLES
        </span>
        </h3>
        <p><span><span>L</span><span>e responsable de la collecte, du traitement et de la
          conservation des Données Personnelles est :
                               </span>
        </span>
        </p>
        <ol>
          <li><span>Pour les
            Données Personnelles collectées dans le cadre de la création du compte personnel de
            l’Utilisateur et de sa navigation sur le Site : Paramedic, Société par Actions
            Simplifiée au capital de 100 000 euros, immatriculée au RCS de Paris sous le numéro
            830 635 272 et dont le siège social est situé au 15 allées de l’Europe, 92110 Clichy
            (N° TVA intracommunautaire : FR 78830635272) (ci-avant et ci-après « Paramedic
            »)</span>
          </li>
          <li><span>Afin de gérer
            les traitements de Données Personnelles qu’elle met en œuvre, Paramedic a nommé un
            Correspondant Informatique et Libertés (ci-après le « CIL »). Le CIL s’assure que
            toutes les précautions utiles ont été prises pour préserver la sécurité des Données
            Personnelles et, notamment, empêcher qu’elles soient déformées, endommagées, ou que
            des personnes non autorisées y aient accès. La désignation d’un CIL témoigne de
            l’engagement de Paramedic en faveur du respect de la vie privée et des droits des
            Utilisateurs dont les Données Personnelles sont traitées.
              </span>
          </li>
          <li><span>Pour les
            Données Personnelles de Santé collectées lors de la demande de transport sanitaire
            par l’Utilisateur via le Site et les Données Personnelles collectées par : Chaque
            Professionnel de Santé individuellement.
              </span>
          </li>
          <li><span>Dans cette
            hypothèse, chaque Professionnel de Santé est considéré comme responsable des
            traitements de Données Personnelles et Paramedic comme sous-traitant des
            Professionnels de Santé. Cela signifie que Paramedic, en sa qualité de
            sous-traitant, ne peut intervenir sur les Données Personnelles traitées par les
            Professionnels de Santé que sur instruction du Professionnel de Santé responsable de
            traitement(s).
              </span>
          </li>
        </ol>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>FINALITE DES DONNEES COLLECTEES</span></h3>
        <p><span>1. Nécessité de la collecte.</span></p>
        <p><span>La plupart des services de Paramedic ne
          requièrent aucune forme d’inscription, ce qui permet à l’Utilisateur de visiter le Site
          sans avoir à s’identifier.
           </span>
        </p>
        <p><span>Cependant, certains services et notamment le
          service de demande de transport sanitaire en ligne auprès d’un peut nécessiter la
          communication de certaines Données Personnelles et/ou Données Personnelles de Santé.
          Dans ces cas, si le patient par le biais du Professionnel de Santé choisit de ne pas
          communiquer les informations qui lui sont demandées, il se peut que l’Utilisateur ne
          puisse pas accéder à certaines parties du Site, et que Paramedic soit dans
          l’impossibilité de répondre à sa demande.
           </span>
        </p>
        <p><span>2. Finalités</span></p>
        <p><span>Les Données Personnelles de l’Utilisateur
          sont collectées par Paramedic afin de permettre la navigation de l’Utilisateur sur le
          Site, le mettre en relation avec un Professionnel de Santé, lui permettre de gérer ses
          transports sanitaires (obtenir des notifications, les modifier, les annuler). Paramedic
          peut également être amené à collecter des Données Personnelles, en tant que
          sous-traitant du Professionnel de Santé, afin de permettre au patient d’être pris en
          charge de façon optimale par les ambulanciers..
           </span>
        </p>
        <p><span>A titre accessoire, les Données Personnelles
          peuvent être collectées par Paramedic dans un but de prévention et lutte contre la
          fraude informatique (spamming, hacking…), afin d’améliorer la navigation de
          l’Utilisateur sur le Site, et de mener des enquêtes facultatives pour l’Utilisateur,
          destinées à recueillir son opinion sur les services de Paramedic.
           </span>
        </p>
        <p><span>A titre subsidiaire, lorsque l’Utilisateur
          est un Professionnel de Santé non abonné à Paramedic et qu’il remplit un formulaire pour
          demander des informations, ces données sont collectées par Paramedic afin de le
          contacter.
           </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>TYPES DE DONNEES COLLECTEES</span></h3>
        <p><span>Paramedic est susceptible de collecter et
          traiter tout ou partie des données suivantes :
           </span>
        </p>
        <ul>
          <li><span>Les données
            que l’Utilisateur fournit en remplissant des formulaires, en effectuant une demande
            de transport sanitaire ou en adressant des correspondances à Paramedic
              </span>
          </li>
          <li><span>Nom, prénom,
            email, téléphone, service et fonction du Professionnel de Santé.
              </span>
          </li>
          <li><span>Nom, prénom,
            genre, date de naissance adresse postale (et exceptionnellement et optionnellement
            digicode) du patient.
              </span>
          </li>
          <li><span>Mot de
            passe
              </span>
          </li>
          <li><span>Motif du
            transport
              </span>
          </li>
          <li><span>Historique des
            transports
              </span>
          </li>
          <li><span>Nom, prénom,
            email, téléphone fixe et mobile du Régulateur de la Société d’ambulances
              </span>
          </li>
          <li><span>Adresse
            postale, téléphone fixe, numéro d’agrément de la Société d’Ambulances
              </span>
          </li>
          <li><span>Nom, prénom,
            email, téléphone, géolocalisation du smartphone de l’ambulancier pendant le temps de
            connexion uniquement.
              </span>
          </li>
        </ul>
        <h2 />
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>NON-COMMUNICATION DES DONNÉES
          PERSONNELLES
        </span>
        </h3>
        <p><span>Les Données Personnelles de l’Utilisateur
          sont destinées à l’usage de Paramedic, des Professionnels de Santé avec lesquels
          l’Utilisateur a pris contact. Elles ne seront pas transmises à des partenaires
          commerciaux et publicitaires.
           </span>
        </p>
        <p><span>Certaines Données Personnelles peuvent
          également être traitées par des sous-traitants (prestataires de service) auxquels
          Paramedic fait appel dans le cadre de l’exécution du Service et afin de concrétiser les
          finalités énoncées à l’article 2.2. de la Politique .
           </span>
        </p>
        <p><span>Paramedic exige de ses prestataires de
          services qu’ils n’utilisent les Données Personnelles que pour réaliser les tâches qui
          leur sont confiées.
           </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>DROIT D’ACCÈS, DE RECTIFICATION ET
          D’OPPOSITION
        </span>
        </h3>
        <p><span>Chaque fois que Paramedic traite des Données
          Personnelles, Paramedic prend toutes les mesures raisonnables pour s’assurer de
          l’exactitude et de la pertinence des Données Personnelles au regard des finalités pour
          lesquelles Paramedic les collecte.
           </span>
        </p>
        <p><span>L&rsquo;Utilisateur bénéficie d&rsquo;un droit d&rsquo;accès et de rectification, ainsi
          que d&rsquo;un droit d&rsquo;opposition pour motif légitime (exception: sans motif
          légitime pour une personne mineure au moment de la collecte).
           </span>
        </p>
        <p><span>Si l’Utilisateur souhaite savoir comment
          Paramedic utilise ses Données Personnelles, demander à les rectifier ou s’opposer à leur
          traitement, l’Utilisateur peut contacter Paramedic par écrit à l’adresse suivante :
           </span>
        </p>
        <p><span>Paramedic – DPO 15 allées de l’Europe ou
          protection-donnees@paramedic.tech
           </span>
        </p>
        <p><span>Dans ce cas, l’Utilisateur doit indiquer les
          Données Personnelles qu’il souhaiterait que Paramedic corrige, mette à jour ou supprime,
          en s’identifiant de manière précise avec une copie d’une pièce d’identité (carte
          d’identité ou passeport).
           </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>SÉCURITÉ</span></h3>
        <p><span>Pour assurer la sécurité et la
          confidentialité des Données Personnelles et des Données Personnelles de Santé, Paramedic
          utilise des réseaux protégés par des dispositifs standards tels que par pare-feu, la
          pseudonymisation, l’encryption et mot de passe. Lors du traitement des Données
          Personnelles, Paramedic prend toutes les mesures raisonnables visant à les protéger
          contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération
          ou destruction.
           </span>
        </p>
        <p><span>Afin de respecter les dispositions du Code de
          la santé publique concernant les Données Personnelles de Santé, Paramedic a recours à
          des hébergeurs agréés de données de santé :
           </span>
        </p>
        <p><span><b>OVH Healthcare, Hébergeur agréé données de santé par l’Asip
          Santé.
                 </b>
        </span>
        </p>
        <p><span><b>SAS au capital de 10 069 020 €`</b></span></p>
        <p><span><b>RCS Lille Métropole 424 761 419 00045</b></span></p>
        <p><span><b>Siège social : 2 rue Kellermann &#8211; 59100 Roubaix
          &#8211; France
        </b>
        </span>
        </p>
        <p><span><span>Il est par ailleurs rappelé que
          chaque Utilisateur contribue à la protection de ses Données Personnelles en se
          conformant aux bonnes pratiques en matière d’utilisation des outils informatiques
          (par exemple ne pas retranscrire son mot de passe) et aux
                 </span><span>Conditions
          Générales
          d’Utilisation</span><span>.</span>
        </span>
        </p>
        <h3 style={{ color: '#2FABE0', margin: '2.5rem 0 .5rem 0' }}><span>NOUS CONTACTER</span></h3>
        <p><span>Si l’Utilisateur a des questions ou des
          réclamations concernant le respect par Paramedic de la présente Politique, ou si
          l’Utilisateur souhaite faire part à Paramedic des recommandations ou des commentaires
          visant à améliorer la qualité de la présente Politique, l’Utilisateur peut contacter
          Paramedic par écrit à l’adresse suivante : Paramedic – DPO, 15 allées de l’Europe, 92110
          Clichy ou protection-donnees@paramedic.tech
           </span>
        </p>
        <p>&nbsp;</p>
        <h2 />
      </div>
    </>
  )
}

export default Confidentialite
